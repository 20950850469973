import { LegacyProductReference } from 'constants/products';
import { OffersExperienceDiscount, OffersExperienceGroup } from 'api/definitions/offers-experience';
import { DateString } from 'utils/date-string.d';

export const MEAL_DEAL_INVALID_ID_ERROR_CODE = 'OFFERS_EXPERIENCE_001';
export const MEAL_DEAL_NOT_FOUND_ERROR_CODE = 'OFFERS_EXPERIENCE_002';
export const MEAL_DEAL_ERROR_FETCHING_OFFER_ERROR_CODE = 'OFFERS_EXPERIENCE_003';
export const MEAL_DEAL_ERROR_FETCHING_PRODUCTS_ERROR_CODE = 'OFFERS_EXPERIENCE_004';
export const MEAL_DEAL_OFFER_NOT_ACTIVE_ERROR_CODE = 'OFFERS_EXPERIENCE_005';
export const MEAL_DEAL_BRANCH_NOT_APPLICABLE_ERROR_CODE = 'OFFERS_EXPERIENCE_006';
export const MEAL_DEAL_ONLY_CONTENT_AVAILABLE_ERROR_CODE = 'OFFERS_EXPERIENCE_010';
export const MEAL_DEAL_MISSING_CUSTOMER_ORDER_ID = 'OFFERS_EXPERIENCE_011';
export const MEAL_DEAL_UNKNOWN_ERROR_CODE = 'OFFERS_EXPERIENCE_UNKNOWN_ERROR_CODE';

export type MealDealId = string;

export type MealDealErrorCode =
  | typeof MEAL_DEAL_INVALID_ID_ERROR_CODE
  | typeof MEAL_DEAL_NOT_FOUND_ERROR_CODE
  | typeof MEAL_DEAL_ERROR_FETCHING_OFFER_ERROR_CODE
  | typeof MEAL_DEAL_ERROR_FETCHING_PRODUCTS_ERROR_CODE
  | typeof MEAL_DEAL_OFFER_NOT_ACTIVE_ERROR_CODE
  | typeof MEAL_DEAL_BRANCH_NOT_APPLICABLE_ERROR_CODE
  | typeof MEAL_DEAL_ONLY_CONTENT_AVAILABLE_ERROR_CODE
  | typeof MEAL_DEAL_MISSING_CUSTOMER_ORDER_ID
  | typeof MEAL_DEAL_UNKNOWN_ERROR_CODE;

export type MealDealRequestState = {
  loading: true;
};

export type MealDealSuccessState = {
  loading: false;
  description: string;
  discount: OffersExperienceDiscount;
  endDate: DateString;
  groups: OffersExperienceGroup[];
  id: MealDealId;
  name: string;
  recommendations: LegacyProductReference[];
  startDate: DateString;
  type: string;
};

export type MealDealErrorState = {
  loading: false;
  errorCode: MealDealErrorCode;
};

export type MealDealState =
  | Record<string, never>
  | MealDealRequestState
  | MealDealSuccessState
  | MealDealErrorState;

export type MealDealDisabled = {
  showAlert: boolean;
  hideBuilder: boolean;
  title?: string;
  cause?: symbol;
  message?: string;
  showGrid: boolean;
  isNowBeforeStartDate: boolean;
  isNowAfterEndDate: boolean;
  isSlotBooked: boolean;
  comingSoonMessage?: string;
};

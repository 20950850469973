import { getProductByLineNumber } from 'redux/modules/entities/selectors/products';
import { getMealDealBuilderItemsFromPromotionGroups } from 'redux/modules/meal-deals/selectors';
import { getMealDealPromotion } from 'redux/modules/entities/selectors/promotions';

const getMealDeal = (state, product) => {
  // TODO: https://www.jlpit.com/jira/browse/WPIP-61729
  const mealDealPromotion = getMealDealPromotion(state, product.promotions);

  if (!mealDealPromotion) {
    return null;
  }

  const { groups, promotionId } = mealDealPromotion;
  const builders = getMealDealBuilderItemsFromPromotionGroups(state, groups);

  return { builders, promotionId };
};

const getCompleteMealDealEvent = (state, lineNumber) => {
  const product = getProductByLineNumber(state, lineNumber);

  if (!product) {
    return null;
  }

  const mealDeal = getMealDeal(state, product);

  if (!mealDeal) {
    return null;
  }

  const completedBuilders = mealDeal.builders.slice(0, -1);

  let selectedLineIsInCompletedMealDealBuilders = false;
  let selectedLineGroupIndex = null;

  for (let builderIndex = 0; builderIndex < completedBuilders.length; builderIndex += 1) {
    if (selectedLineIsInCompletedMealDealBuilders) {
      break;
    }

    const { groups } = completedBuilders[builderIndex];

    for (let groupIndex = 0; groupIndex < groups.length; groupIndex += 1) {
      const group = groups[groupIndex];
      const groupContainsLine = group.items.some(item => item?.lineNumber === lineNumber);

      if (groupContainsLine) {
        selectedLineIsInCompletedMealDealBuilders = true;
        selectedLineGroupIndex = groupIndex;

        break;
      }
    }
  }

  if (selectedLineGroupIndex === null) {
    return null;
  }

  const lastBuilderGroups = mealDeal.builders.at(-1).groups;
  const selectedLineGroupEmptyInLastBuilder = lastBuilderGroups[selectedLineGroupIndex].items.every(
    item => item === null,
  );

  const mealDealCompleted =
    selectedLineIsInCompletedMealDealBuilders && selectedLineGroupEmptyInLastBuilder;

  if (!mealDealCompleted) {
    return null;
  }

  return {
    event: 'complete_meal_deal',
    meal_deal: {
      meal_deal_id: mealDeal.promotionId,
    },
  };
};

export default getCompleteMealDealEvent;

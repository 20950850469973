import React from 'react';
import urls from 'constants/urls';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';
import { getFeatureFlags } from 'utils/feature-flags';
import { Link } from 'react-router-dom';
import { storeLoginRedirect } from 'utils/login';
import locator from 'utils/locator';

type LoginLinkProps = React.ComponentProps<typeof TextLink>;

export const LoginLink: React.FC<LoginLinkProps> = ({ children, ...rest }) => {
  const { identity_enableOAuth2Web: OAuth2Enabled } = getFeatureFlags();

  const handleClick = () => {
    storeLoginRedirect(locator.pathname + locator.search);
  };

  return (
    <TextLink
      component={Link}
      componentProps={{ to: urls.login }}
      onClick={OAuth2Enabled ? handleClick : undefined}
      {...rest}
    >
      {children}
    </TextLink>
  );
};

export default LoginLink;

import {
  SORT_URL_CATEGORY_RANKING,
  SORT_URL_PURCHASE_FREQUENCY,
} from 'redux/modules/favourites/constants';

export const favouritesSortOptions = [
  {
    children: 'Category',
    value: SORT_URL_CATEGORY_RANKING,
  },
  {
    children: 'Purchase frequency',
    value: SORT_URL_PURCHASE_FREQUENCY,
  },
];

import { createSelector } from 'reselect';
import { getFeatureFlags } from 'utils/feature-flags';
import { getFavourites } from './get-favourites';

const EMPTY_FAVOURITES_PRODUCTS_ARRAY: never[] = [];

const getFavouritesLegacy = (state: WtrState) => state.favourites;

export const getFavouritesProductsLegacy = createSelector(
  [getFavouritesLegacy],
  favourites => favourites.products || EMPTY_FAVOURITES_PRODUCTS_ARRAY,
);

const getFavouritesProductsExperience = createSelector(
  [getFavourites],
  favourites => favourites.componentsAndProducts || EMPTY_FAVOURITES_PRODUCTS_ARRAY,
);

// TODO: replace with getFavouritesProductsExperience post go-live
// https://www.jlpit.com/jira/browse/WPIP-62983
export const getFavouritesProducts = (state: WtrState) => {
  const { build_favouritesExperience: favouritesExperience } = getFeatureFlags();

  return favouritesExperience
    ? getFavouritesProductsExperience(state)
    : getFavouritesProductsLegacy(state);
};

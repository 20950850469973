import urls from 'constants/urls';

const authenticationUrls = [
  urls.registration,
  urls.login,
  urls.signIn,
  urls.resetPassword,
  urls.forgotPassword,
];

const isAuthenticationUrl = path => authenticationUrls.some(url => path.includes(url));

const reducer = (state, action) => {
  if (
    action?.payload?.location?.pathname &&
    !isAuthenticationUrl(action.payload.location.pathname)
  ) {
    return {
      ...state,
      lastNonAuthenticationPath: `${action.payload.location.pathname}${
        action.payload.location.search || ''
      }`,
    };
  }
  return state;
};

export default reducer;

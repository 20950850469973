import get from 'lodash/get';
import update from 'immutability-helper';
import { LOCATION_CHANGE } from 'connected-react-router';
import { GET_MEAL_DEAL } from 'redux/modules/meal-deals/actions/types';
import { deepFreeze } from 'utils/deepFreeze';

import {
  INTERSTITIALS_FULFILLED,
  INTERSTITIALS_UPDATED,
} from 'redux/modules/interstitials/actions/types';

import {
  CLEAR,
  FAILED,
  INVALIDATEPRODUCTS,
  LOADED,
  LOADED_CHRISTMAS_HUB,
  LOADING,
  MEAL_DEAL_PRODUCTS_LOADED,
  NEXTLOADED,
  OFFER_PRODUCTS_LOADED,
  RESETERROR,
  SETACTIVEPROMOTION,
  RECOMMENDATIONS_PLP_PRODUCTS_FULFILLED,
} from 'redux/modules/search-and-browse/actions/types';
import { PRODUCT_LOADED, PRODUCT_FAILED } from 'redux/modules/product-details/actions/types';
import { SHOPPING_LIST_PRODUCTS_FULFILLED } from 'redux/modules/shopping-list/actions/types';
import {
  APPLYFILTER,
  CLEARFILTERS,
  REMOVEFILTER,
  SORTBY,
  UPDATESEARCHTERM,
} from 'redux/modules/search/actions/types';
import { setFilterAppliedStatus } from 'redux/modules/search-and-browse/reducer/setFilterAppliedStatus';
import { uniqueCategoryLevelFilters } from 'redux/modules/search-and-browse/reducer/uniqueCategoryLevelFilters';
import { START_LOADING } from 'redux/modules/multi-search/actions/types';

const initialErrors = {
  error: false,
  errorResponse: null,
};

const initialState = deepFreeze({
  ...initialErrors,
  activeProduct: null,
  activePromotion: null,
  addAllToBasket: false,
  breadcrumbs: [],
  criteria: null, // response criteria
  displayedProducts: 0,
  hasMore: false,
  hasResults: false,
  loading: false,
  loadingMore: false,
  locations: {},
  metaData: null,
  pageSubTitle: {},
  showPageTitle: true,
  pageTitle: {},
  products: [],
  redirectUrl: null,
  request: null, // request criteria
  searchType: null,
  searchTypeHistoric: [],
  subCategories: [],
  totalMatches: 0,
  recommendations: [],
});

const setPageTitle = ({ pageTitle = {} } = {}) => {
  if (typeof pageTitle === 'string') {
    return {
      display: true,
      text: pageTitle,
    };
  }

  return pageTitle;
};

const setPageSubTitle = ({ pageSubTitle = {} } = {}) => {
  if (typeof pageSubTitle === 'string') {
    return {
      display: true,
      text: pageSubTitle,
    };
  }

  return pageSubTitle;
};

const getSearchType = pathname => {
  if (pathname.indexOf('search') !== -1) return 'search';
  if (pathname.indexOf('offers') !== -1) return 'offers';
  if (pathname.indexOf('product') !== -1) return 'product';

  return pathname;
};

export default function searchAndBrowse(state = initialState, action = {}) {
  switch (action.type) {
    case INTERSTITIALS_FULFILLED:
    case INTERSTITIALS_UPDATED: {
      const { result: { breadcrumbs = null, criteria = null, metaData = null } = {}, result } =
        action;

      return {
        ...state,
        breadcrumbs,
        criteria,
        metaData,
        addAllToBasket: false,
        pageSubTitle: setPageSubTitle(result),
        pageTitle: setPageTitle(result),
      };
    }
    case LOCATION_CHANGE: {
      const {
        location: { pathname },
        isFirstRendering,
      } = action.payload;

      // location change is fired on initial page load as well as router change,
      // if the page has been SSRed, we don't want to reset the information
      if (isFirstRendering) {
        return state;
      }

      return {
        ...state,
        ...initialErrors,
        searchTypeHistoric: state.searchTypeHistoric.concat(getSearchType(pathname)),
      };
    }
    case LOADING:
      return {
        ...state,
        ...initialErrors,
        recommendations: action.start ? state.recommendations : [],
        [action.start ? 'loadingMore' : 'loading']: true,
      };
    case LOADED:
    case MEAL_DEAL_PRODUCTS_LOADED:
    case OFFER_PRODUCTS_LOADED: {
      if (action.result.redirectUrl && !action.noRedirects) {
        return {
          ...state,
          ...initialErrors,
          redirectUrl: action.result.redirectUrl,
        };
      }

      return {
        ...state,
        ...initialErrors,
        addAllToBasket: get(action, 'result.addAllToBasket', false),
        breadcrumbs: get(action, 'result.breadcrumbs', null),
        categoryLevelFilters: uniqueCategoryLevelFilters(action.result.categoryLevelFilters),
        criteria: action.result.criteria,
        displayedProducts: action.result.productsInResultset,
        hasMore: action.start + action.result.productsInResultset < action.result.totalMatches,
        hasResults: get(action, 'result.componentsAndProducts', []).length > 0,
        [action.start ? 'loadingMore' : 'loading']: false,
        metaData: get(action, 'result.metaData', null),
        showPageTitle: action.result?.showPageTitle ?? true,
        pageTitle: setPageTitle(action.result),
        pageSubTitle: setPageSubTitle(action.result),
        products: action.result.componentsAndProducts,
        request: action.request,
        searchType: get(action, 'request.name'),
        subCategories: action.result.subCategories,
        totalMatches: action.result.totalMatches,
        experiment: action.result.experiment,
      };
    }
    case GET_MEAL_DEAL.success:
      return {
        ...state,
        ...initialErrors,
        hasResults: action.result.products.length > 0,
        products: action.result.products,
        totalMatches: action.result.totalMatches,
      };
    case LOADED_CHRISTMAS_HUB:
      return {
        ...state,
        loading: false,
      };
    case NEXTLOADED: {
      const displayedProducts = action.result.productsInResultset + state.displayedProducts;

      return {
        ...state,
        ...initialErrors,
        loadingMore: false,
        products: update(state.products, { $push: action.result.componentsAndProducts }),
        totalMatches: action.result.totalMatches,
        displayedProducts,
        criteria: action.result.criteria,
        hasMore: displayedProducts < action.result.totalMatches,
        hasResults: get(action, 'result.componentsAndProducts', []).length > 0,
      };
    }
    case FAILED:
      return {
        ...state,
        [action.start ? 'loadingMore' : 'loading']: false,
        error: true,
        hasMore: false,
        hasResults: false,
        errorResponse: action.error,
      };
    case PRODUCT_LOADED:
    case PRODUCT_FAILED: {
      return {
        ...state,
        ...initialErrors,
        activePromotion: null,
      };
    }
    case APPLYFILTER:
    case REMOVEFILTER: {
      if (!state.criteria?.filters?.length) {
        return state;
      }

      const {
        appliedFilter: { group, id },
        type,
      } = action;

      const updatedFilters = setFilterAppliedStatus(state.criteria.filters, {
        applied: type === APPLYFILTER,
        group,
        id,
      });

      return {
        ...state,
        loading: true,
        criteria: { ...state.criteria, filters: updatedFilters },
      };
    }
    case SORTBY:
    case START_LOADING:
    case UPDATESEARCHTERM: {
      return {
        ...state,
        loading: true,
      };
    }
    case CLEARFILTERS: {
      return {
        ...state,
        loading: true,
        criteria: {
          ...state.criteria,
          filters: state.criteria.filters.map(group => ({
            ...group,
            filters: group.filters.map(filter => ({ ...filter, applied: false })),
          })),
        },
      };
    }
    case CLEAR:
      return {
        ...state,
        ...initialErrors,
        addAllToBasket: false,
        breadcrumbs: [],
        criteria: null,
        displayedProducts: 0,
        hasMore: false,
        hasResults: false,
        loading: false,
        metaData: null,
        pageTitle: {},
        products: [],
        request: null,
        searchType: null,
        totalMatches: 0,
      };
    case INVALIDATEPRODUCTS:
      return {
        ...state,
        hasResults: false,
        loading: false,
        products: [],
      };
    case SETACTIVEPROMOTION:
      return {
        ...state,
        activePromotion: action.promotionId,
      };
    case SHOPPING_LIST_PRODUCTS_FULFILLED: {
      const {
        result: { products = [], totalMatches },
      } = action;

      return {
        ...state,
        ...initialErrors,
        displayedProducts: products.length,
        hasMore: totalMatches - products.length > 0,
        hasResults: products.length > 0,
        products,
        searchType: 'shopping-list',
        totalMatches,
      };
    }
    case RESETERROR:
      return {
        ...state,
        ...initialErrors,
      };
    case RECOMMENDATIONS_PLP_PRODUCTS_FULFILLED:
      return {
        ...state,
        recommendations: action.result.products,
        totalRecommendations: action.result.totalResults,
      };
    default:
      return state;
  }
}

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import PortalModal from 'components/PortalModal';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';
import { InputRadio, InputRadioGroup } from 'components/Forms/FormFields';
import urls from 'constants/urls';

import styles from '../CookieAlert.scss';

const toBoolean = value => value === 'true';

const FullCookieModal = ({ cookies, returnUrl, updatePreferences }) => {
  const [functional, setFunctional] = useState(cookies.functional);
  const [analytics, setAnalytics] = useState(cookies.analytics);
  const [marketing, setMarketing] = useState(cookies.marketing); // advertising

  const handleClose = () => {
    updatePreferences(
      {
        marketing: toBoolean(marketing),
        analytics: toBoolean(analytics),
        functional: toBoolean(functional),
      },
      cookies,
      returnUrl,
    );
  };

  const canSubmitCookie = marketing && analytics && functional;

  return (
    <PortalModal closeOnOverlayClick={false} show={cookies.showPrivacyControls}>
      <form onSubmit={() => {}} name="marketing-preferences">
        <section className={styles.modal} data-testid="cookie-consent-modal">
          <h2 className={styles.privacyModalHeader}>Our Cookies</h2>
          <div className={styles.optionsContainer}>
            <p>
              Our website uses cookies to improve your experience and personalise content. Cookies
              are small files placed on your computer or mobile device when you visit a website.
              They are widely used to improve your experience of a website, gather reporting
              information and show relevant advertising. You can allow all cookies or manage them
              for yourself. You can find out more on our{' '}
              <TextLink
                component={Link}
                to={urls.waitroseCookies}
                className={styles.waitroseCookiesLink}
              >
                cookies page
              </TextLink>{' '}
              any time.
            </p>
            <h3>Essential Cookies</h3>
            <p className={styles.cookieOptionDescription}>
              These cookies are needed for essential functions such as signing in, storing products
              in your trolley and making payments. They can&apos;t be switched off.
            </p>
            <InputRadioGroup
              className={styles.radioContainer}
              inlineAtBreakpoint="sm"
              value
              name="essentialCookies"
              type="radio"
            >
              <InputRadio disabled id="essential-cookies-confirm" label="Allow" checked value />
            </InputRadioGroup>
            <h3>Functional Cookies</h3>
            <p className={styles.cookieOptionDescription}>
              By accepting functional cookies, you will be able to see certain features on our site
              that aren&apos;t essential to its running but still add significant value to
              customers. If you choose not to accept these cookies, you can update your preferences
              at any time.
            </p>
            <InputRadioGroup
              className={styles.radioContainer}
              inlineAtBreakpoint="sm"
              name="functionalCookies"
              onChange={value => setFunctional(value)}
              value={functional}
              type="radio"
            >
              <InputRadio id="functional-cookies-confirm" label="Allow" value="true" />
              <InputRadio id="functional-cookies-deny" label="Don't allow" value="false" checked />
            </InputRadioGroup>
            <h3>Analytical Cookies</h3>
            <p className={styles.cookieOptionDescription}>
              These cookies help us optimise our website based on data. Using these cookies we will
              know which web pages customers enjoy reading most and what products are most popular.
            </p>
            <InputRadioGroup
              className={styles.radioContainer}
              inlineAtBreakpoint="sm"
              name="analyticsCookies"
              onChange={value => setAnalytics(value)}
              value={analytics}
              type="radio"
            >
              <InputRadio id="analytics-cookies-confirm" label="Allow" value="true" />
              <InputRadio id="analytics-cookies-deny" label="Don't allow" value="false" checked />
            </InputRadioGroup>
            <h3>Advertising Cookies</h3>
            <p className={styles.cookieOptionDescription}>
              These cookies are used to help prevent you from seeing irrelevant advertising. They
              are used to advertise Waitrose & Partners items when you visit other websites based on
              what you&apos;ve browsed, searched for or bought.
            </p>
            <InputRadioGroup
              className={styles.radioContainer}
              inlineAtBreakpoint="sm"
              name="marketingCookies"
              onChange={value => setMarketing(value)}
              value={marketing}
              type="radio"
            >
              <InputRadio id="marketing-cookies-confirm" label="Allow" value="true" />
              <InputRadio id="marketing-cookies-deny" label="Don't allow" value="false" checked />
            </InputRadioGroup>
          </div>
          <section className={styles.fullModalAcceptCTA}>
            <Button
              data-testid="submit"
              disabled={!canSubmitCookie}
              type="button"
              width="standard"
              onClick={handleClose}
            >
              Accept selected
            </Button>
          </section>
        </section>
      </form>
    </PortalModal>
  );
};

FullCookieModal.propTypes = {
  cookies: PropTypes.shape({
    analytics: PropTypes.string,
    functional: PropTypes.string,
    marketing: PropTypes.string,
    showPrivacyControls: PropTypes.bool.isRequired,
  }).isRequired,
  returnUrl: PropTypes.string.isRequired,
  updatePreferences: PropTypes.func.isRequired,
};

export default FullCookieModal;

export const MEAL_PLANNER_LOADING = 'waitrose/meal-planner/loading';
export const MEAL_PLANNER_SUCCESS = 'waitrose/meal-planner/success';
export const MEAL_PLANNER_FAILURE = 'waitrose/meal-planner/failure';

export const MEAL_PLANNER_FETCH_ACTIONS = [
  MEAL_PLANNER_LOADING,
  MEAL_PLANNER_SUCCESS,
  MEAL_PLANNER_FAILURE,
] as const;

export const MEAL_PLANNER_UPDATE = 'waitrose/meal-planner/update';

import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';

export const getCookies = state => state?.cookies;

export const getConsentedToCookieState = createSelector(
  getCookies,
  cookies => cookies?.showInitialModal === false,
);

export const getExperimentalCookieState = createSelector(
  getCookies,
  cookies => cookies?.experimental,
);

export const getFunctionalCookieState = createSelector(getCookies, cookies => cookies?.functional, {
  memoizeOptions: {
    resultEqualityCheck: isEqual,
  },
});

export default {
  meta: {
    title: 'Waitrose.com',
    description:
      'Welcome to Waitrose & Partners online store. Browse quality groceries, inspiring recipes and local store information. Picked, packed & delivered with care.',
    head: {
      titleTemplate: '%s | Waitrose & Partners',
      meta: [
        {
          name: 'description',
          content:
            'Welcome to Waitrose & Partners online store. Browse quality groceries, inspiring recipes and local store information. Picked, packed & delivered with care.',
        },
        { charset: 'utf-8' },
        { property: 'og:site_name', content: 'Waitrose.com' },
        {
          property: 'og:image',
          content:
            'https://waitrose-prod.scene7.com/is/image/waitroseprod/apple-touch-icon-114x114-precomposed?$Waitrose-Image-Preset-95$',
        },
        { property: 'og:locale', content: 'en_US' },
        { property: 'og:title', content: 'Waitrose & Partners' },
        {
          property: 'og:description',
          content: 'Buy quality groceries and wine from Waitrose & Partners',
        },
        { property: 'og:card', content: 'summary' },
        { property: 'og:site', content: 'Waitrose & Partners' },
        { property: 'og:creator', content: 'Waitrose & Partners' },
        { property: 'og:image:width', content: '114' },
        { property: 'og:image:height', content: '114' },
      ],
    },
  },
};

import React, { useCallback } from 'react';
import { bool } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { yieldToMain } from 'utils/yield-to-main';
import { generatePDPSlug } from 'utils/product';

import wcsConfig from 'utils/wcs-config';

import urls from 'constants/urls';

import { Document, HeartInactive } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';
import { Link } from 'react-router-dom';
import { setPageLoading } from 'redux/modules/page/actions/set-page-loading';

import SignIn from 'components/AuthenticationAction/SignIn';
import { pushMenuClick } from 'utils/gtm';
import { getPathName } from 'redux/modules/slot-booking/selectors/get-fulfilment-type';
import SeasonalMenu from '../SeasonalMenu';

import MyAccount from './MyAccount/MyAccount';

import styles from './LinksBar.scss';

const ConnectedLinksBar = ({ isLoggedIn }) => {
  const dispatch = useDispatch();
  const pathname = useSelector(getPathName);

  const menuClickHandler = useCallback(
    event => {
      if (!pathname.includes(generatePDPSlug(event.target.textContent))) dispatch(setPageLoading());
      yieldToMain();
      pushMenuClick(event.target.textContent);
    },
    [dispatch, pathname],
  );

  const unauthenticatedAccountLinks = (
    <nav
      className={styles.secondaryLinks}
      data-test="account-link-login"
      aria-label="Sign in and register"
    >
      <SignIn className={styles.links} />
    </nav>
  );

  const authenticatedAccountLinks = (
    <nav aria-label="Account links" className={styles.secondaryLinks}>
      <TextLink
        component={Link}
        id="site-header-favourites"
        to={urls.favourites}
        className={styles.links}
        onClick={menuClickHandler}
        underline="none"
      >
        <HeartInactive className={styles.favouritesIcon} size="medium" />
        Favourites
      </TextLink>
      <>
        <TextLink
          component={Link}
          id="site-header-my-orders"
          to={urls.myOrdersPage}
          className={styles.links}
          data-test="site-header-my-orders"
          onClick={menuClickHandler}
          underline="none"
        >
          <Document className={styles.ordersIcon} />
          My orders
        </TextLink>
        <MyAccount />
      </>
    </nav>
  );

  const accountLinks = !isLoggedIn ? unauthenticatedAccountLinks : authenticatedAccountLinks;

  return (
    <div className={styles.siteHeaderLinks}>
      <nav className={classNames(styles.primaryLinks)} aria-label="Main navigation">
        <SeasonalMenu />
        <TextLink
          component={Link}
          to={urls.offers}
          className={styles.offers}
          id="site-header-offers"
          data-testid="site-header-offers"
          onClick={menuClickHandler}
          underline="none"
        >
          Offers
        </TextLink>
        <TextLink
          component={Link}
          to={urls.entertainingHome}
          className={styles.links}
          data-testid="site-header-entertaining"
          id="site-header-entertaining"
          onClick={menuClickHandler}
          underline="none"
        >
          Entertaining
        </TextLink>
        <TextLink
          component={Link}
          id="site-header-new"
          data-testid="site-header-new"
          className={styles.links}
          to={urls.new}
          onClick={menuClickHandler}
          underline="none"
        >
          New
        </TextLink>
        <TextLink
          component={Link}
          id="site-header-recipes"
          className={styles.links}
          to="/ecom/recipes"
          onClick={menuClickHandler}
          underline="none"
        >
          Recipes
        </TextLink>
        <TextLink
          id="site-header-inspiration"
          className={styles.links}
          href={wcsConfig.inspirationUrl()}
          onClick={menuClickHandler}
          underline="none"
        >
          Inspiration
        </TextLink>
      </nav>
      {accountLinks}
    </div>
  );
};

ConnectedLinksBar.displayName = 'Links';

ConnectedLinksBar.propTypes = {
  isLoggedIn: bool,
};

ConnectedLinksBar.defaultProps = {
  isLoggedIn: false,
};

export default ConnectedLinksBar;

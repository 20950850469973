import env from 'env/env';

export const useTrolleyAnalytics = () => {
  const trackAbandonTrolleyUpdateEvent = (
    pageLocation: string | null,
    clientId: string | null,
    sessionId: string | null,
    customerId: string,
    orderId: string,
  ) => {
    if (__SERVER__) return;

    navigator.sendBeacon(
      `${env.googleAnalytics.collectApiUrl}?measurement_id=${env.googleAnalytics.measurementId}&api_secret=${env.googleAnalytics.apiKey}`,
      JSON.stringify({
        client_id: clientId,
        user_properties: {
          customer_id: {
            value: customerId,
          },
        },
        events: [
          {
            name: 'abandoned_trolley_update',
            params: {
              page_location: pageLocation,
              order_id: orderId,
              session_id: sessionId,
            },
          },
        ],
      }),
    );
  };

  return {
    trackAbandonTrolleyUpdateEvent,
  };
};

import createReducer from 'redux/createReducer';
import update from 'immutability-helper';
import { LegacyProductReference } from 'constants/products';

import { FavouritesExperienceResponse } from 'api/definitions/favourites-experience';
import { isFavouritesSuccessfullyLoaded } from 'redux/modules/favourites/selectors/get-favourites';
import { FAVOURITES_SET_SORT_ORDER, GET_FAVOURITES } from '../actions/types';
import { FAVOURITES_SORT_API_TO_URL_MAP, SORT_URL_CATEGORY_RANKING } from '../constants';

import { initialState } from '../state';
import {
  FavouritesExperienceRequestState,
  FavouritesExperienceSuccessState,
  FavouritesExperienceState,
  TrimmedCategory,
  Criteria,
  FilterCategory,
} from '../types';

interface FavouritesExperienceFetcherResult
  extends Omit<
    FavouritesExperienceResponse,
    'recommendations' | 'content' | 'componentsAndProducts'
  > {
  componentsAndProducts: LegacyProductReference[];
  recommendations: LegacyProductReference[];
  criteria: Criteria;
  filterCategories: FilterCategory[];
}

export type FavouritesExperienceRequestAction = {
  start: number;
  onOffer: boolean;
  sortBy: string;
  categoryId: string | null;
};

export type FavouritesExperienceSuccessAction = {
  result: FavouritesExperienceFetcherResult;
  start: number;
  categoryId: string | undefined;
};

export type FavouritesSortByAction = {
  payload: {
    sortBy: string;
  };
};

const getFavouritesExperienceRequest = (
  state: FavouritesExperienceState,
  { onOffer, sortBy, start, categoryId }: FavouritesExperienceRequestAction,
): FavouritesExperienceRequestState => ({
  ...state,
  offersFilter: !!onOffer,
  sortBy,
  categoryIdFilter: categoryId,
  loading: !start,
  loadingMore: !!start,
});

const getCategories = (
  state: FavouritesExperienceState,
  {
    categories,
    isLoadMore,
  }: { categories: FavouritesExperienceFetcherResult['categories']; isLoadMore: boolean },
) => {
  if (!categories) {
    return [];
  }

  const trimmedCategories: TrimmedCategory[] = categories.map(
    ({ name, id, orderedComponentsAndProducts }) => ({
      name,
      id,
      orderedComponentsAndProducts,
    }),
  );

  if (!isLoadMore) {
    return trimmedCategories;
  }

  const categoriesState = (state as FavouritesExperienceSuccessState).categories;
  const lastCategoryInState = categoriesState[categoriesState.length - 1];
  const firstCategoryInTrimmed = trimmedCategories[0];

  if (lastCategoryInState.id === firstCategoryInTrimmed.id) {
    trimmedCategories[0].orderedComponentsAndProducts = [
      ...lastCategoryInState.orderedComponentsAndProducts,
      ...firstCategoryInTrimmed.orderedComponentsAndProducts,
    ];
  }

  return update(categoriesState, { $push: trimmedCategories });
};

const getFavouritesExperienceSuccess = (
  state: FavouritesExperienceState,
  {
    result: {
      categories: rawCategories,
      componentsAndProducts,
      filterCategories,
      recommendations,
      criteria,
      ignoreCategories,
      metadata,
    },
    start,
    categoryId,
  }: FavouritesExperienceSuccessAction,
): FavouritesExperienceSuccessState => {
  const isLoadMore = !!start && isFavouritesSuccessfullyLoaded(state);
  const categories = getCategories(state, { categories: rawCategories, isLoadMore });
  const offerGroup = criteria?.filters.find(filter => filter.group === 'OFFER_TYPE');
  const offersFilter = offerGroup?.filters[0].applied || false;
  const sortByValue = metadata?.sortBy as keyof typeof FAVOURITES_SORT_API_TO_URL_MAP;
  const sortBy = FAVOURITES_SORT_API_TO_URL_MAP[sortByValue] ?? SORT_URL_CATEGORY_RANKING;

  return {
    loading: false,
    loadingMore: false,
    categories,
    componentsAndProducts: isLoadMore
      ? update(state.componentsAndProducts, { $push: componentsAndProducts })
      : componentsAndProducts,
    categoryIdFilter: categoryId,
    rootCategories: filterCategories,
    ignoreCategories,
    recommendations,
    totalResults: metadata.totalResults,
    offersFilter,
    sortBy,
  };
};

const setFavouritesSortBy = (
  state: FavouritesExperienceState,
  { payload: { sortBy } }: FavouritesSortByAction,
) => ({
  ...state,
  sortBy,
});

const actionTypeReducerMap = [
  [GET_FAVOURITES.request, getFavouritesExperienceRequest],
  [GET_FAVOURITES.success, getFavouritesExperienceSuccess],
  [FAVOURITES_SET_SORT_ORDER, setFavouritesSortBy],
] as const;

export type PayloadMap = ReducerMapTupleToRecord<typeof actionTypeReducerMap>;
export default createReducer(initialState, actionTypeReducerMap);

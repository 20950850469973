import { FailedOrderPaymentResponse } from 'api/definitions/failed-order-payments';
import { ApiError } from 'constants/errors';
import { CheckoutState } from '../state/initial-state';

interface Action {
  orderId: string;
}

function updateResolveOrderPayment(
  state: CheckoutState,
  orderId: string,
  value: CheckoutState['resolveOrderPayment'][string],
) {
  const { resolveOrderPayment, ...rest } = state;

  return {
    ...rest,
    resolveOrderPayment: {
      ...resolveOrderPayment,
      [orderId]: value,
    },
  };
}

export const getFailedOrderPaymentFailure: ApiErrorReducer<CheckoutState, Action, ApiError> = (
  state,
  action,
) => {
  return updateResolveOrderPayment(state, action.orderId, {
    loading: false,
    loaded: false,
    error: true,
  });
};

export const getFailedOrderPaymentRequest: ApiRequestReducer<CheckoutState, Action> = (
  state,
  action,
) => {
  return updateResolveOrderPayment(state, action.orderId, {
    loading: true,
    loaded: false,
    error: false,
  });
};

export const getFailedOrderPaymentSuccess: ApiSuccessReducer<
  CheckoutState,
  Action,
  FailedOrderPaymentResponse
> = (state, { orderId, payload }) => {
  return updateResolveOrderPayment(state, orderId, {
    loading: false,
    loaded: true,
    error: false,
    paymentResolutionEnabled: payload.paymentResolutionEnabled,
    resolved: payload.resolved,
    paymentAttemptAllowed: payload.paymentAttemptAllowed,
    totalToPay: payload.totalToPay,
  });
};

import { CLEAR, LOADED } from 'redux/modules/content/actions/types';
import {
  INTERSTITIALS_FULFILLED,
  INTERSTITIALS_UPDATED,
} from 'redux/modules/interstitials/actions/types';
import {
  HOMEPAGE_LOADED,
  LOADED as SEARCHANDBROWSE_LOADED,
  LOADED_CHRISTMAS_HUB,
} from 'redux/modules/search-and-browse/actions/types';
import { GET_MEAL_DEAL } from 'redux/modules/meal-deals/actions/types';

import initialState from 'redux/modules/content/reducer/initial-state';

import addColumnComponentToContentResponse from 'components/ContentLocation/add-column-component-to-content-response';

export default (state = initialState, action = {}) => {
  const { result = {}, type } = action;

  switch (type) {
    case CLEAR: {
      return {
        ...state,
        addAllToBasket: false,
        locations: {},
        locationsLoaded: false,
      };
    }
    case HOMEPAGE_LOADED:
    case INTERSTITIALS_FULFILLED:
    case INTERSTITIALS_UPDATED:
    case SEARCHANDBROWSE_LOADED:
    case GET_MEAL_DEAL.success: {
      const { addAllToBasket, locations, content } = result;

      return {
        ...state,
        ...(addAllToBasket && { addAllToBasket }),
        // this transformation has been lifted out of the content location to ensure that it is only
        // applied once and we do not fall fowl of the change detection causing additional re-renders of
        // the content sections of the content tree. The transformation is not new, it is just better applied here.
        locations: addColumnComponentToContentResponse((locations || content) ?? {}),
        locationsLoaded: true,
      };
    }
    case GET_MEAL_DEAL.failure: {
      const content = action.payload?.response?.body?.content;

      return {
        ...state,
        // this transformation has been lifted out of the content location to ensure that it is only
        // applied once and we do not fall fowl of the change detection causing additional re-renders of
        // the content sections of the content tree. The transformation is not new, it is just better applied here.
        locations: addColumnComponentToContentResponse(content ?? {}),
        locationsLoaded: true,
      };
    }
    case LOADED_CHRISTMAS_HUB: {
      return {
        ...state,
        locations: addColumnComponentToContentResponse(action.result.locations ?? {}),
        locationsLoaded: true,
      };
    }
    case LOADED: {
      const { locations } = action;

      return {
        ...state,

        // this transformation has been lifted out of the content location to ensure that it is only
        // applied once and we do not fall fowl of the change detection causing additional re-renders of
        // the content sections of the content tree. The transformation is not new, it is just better applied here.
        locations: addColumnComponentToContentResponse(locations ?? {}),
        locationsLoaded: true,
      };
    }
    default: {
      return state;
    }
  }
};

import createReducer from 'redux/createReducer';
import {
  NAV_TO_RECIPE,
  PRODUCTS_BY_RECIPE_ID,
  RECIPE_BY_ID,
  RECIPE_RATING,
  SHOPPABLE_PRICING_REQUEST_SUCCESS,
  SWAP_SHOPPABLE_PRICING_REQUEST_SUCCESS,
  UPDATE_SHOPPABLE_INGREDIENT_BY_ID,
} from 'redux/modules/recipes/actions/types';

import { formatAsPositivePoundsOrPence } from 'utils/currency';
import uuid from 'uuid';
import { Recipe, RecipeProductsType, AemBlock } from 'api/definitions/recipes/index.d';
import { STORE_CUPBOARD, CORE } from '../constants';
import initialState from './initial-state';

export type MatchStrategy = 'RECIPE_LEVEL' | 'INGREDIENT_LEVEL' | 'CATEGORY_SEARCH' | 'SEARCH';

export type Ingredient = {
  id: string;
  ingredientId: string;
  title: string;
  lineNumber: string;
  products: {
    lineNumber: string;
    quantity: number;
    matchStrategy: MatchStrategy;
  }[];
  isSponsored: boolean;
  isEnforced: boolean;
  type: string;
  uom: string;
  amountSelected: number;
  uuid?: string;
  totalPrice: string;
};

type Rating = {
  header: string;
  status: string;
  average: number;
};

export type RecipeStateItem = Recipe & {
  loading: boolean;
  rating: {
    average: number;
    totalRatings: number;
    customerRating: number;
    loaded: boolean;
    error?: boolean;
  };
  shoppableProducts: {
    ingredients: {
      storeCupboard: Ingredient[];
      nonStoreCupboard: Ingredient[];
    };
  }[];
  products?: {
    ingredients: RecipeProductsType['products'];
  }[];
};

export type RecipesState = Record<string, RecipeStateItem> & {
  id: Recipe['id'];
  title: string; // deprecated
  description?: {
    plainText?: string;
    blocks: AemBlock[];
  } | null;
};

export type RecipesErrorState = Record<string, { pimsError: boolean }>;

type RecipeRequest = {
  recipeId: Recipe['id'];
};

type RecipeAction = {
  result: Recipe;
};

type PimsResult = {
  ingredients: (Partial<Ingredient> & Pick<Ingredient, 'products'>)[];
};

type PimsAction = {
  result: Partial<PimsResult>;
  id: Recipe['id'];
};

type NavAction = {
  recipe: Recipe;
};

type DeepPartial<T> = T extends any[] // eslint-disable-line @typescript-eslint/no-explicit-any
  ? T
  : T extends Record<string, any> // eslint-disable-line @typescript-eslint/no-explicit-any
    ? {
        [P in keyof T]?: DeepPartial<T[P]>;
      }
    : T;

const defaultRecipe: DeepPartial<RecipeStateItem> = {
  nutritional: {},
  rating: {
    average: 0,
    totalRatings: 0,
  },
  shoppableProducts: [],
};

const recipeByIdRequest = (state: RecipesState, action: RecipeRequest) => ({
  ...state,
  loading: true,
  [action.recipeId]: {
    ...defaultRecipe,
    ...state[action.recipeId],
    loading: true,
  },
});

const recipeByIdSuccess = (state: RecipesState, action: RecipeAction) => ({
  ...state,
  ...action.result,
  loading: false,
  [action.result.id]: {
    ...defaultRecipe,
    ...state[action.result.id],
    ...action.result,
    loading: false,
  },
});

type UpdateShoppableIngredientByIdArgs = {
  recipeId: Recipe['id'];
  groupId: number | null;
  ingredientId: string;
  updatedIngredient: object;
};

const updateShoppableIngredientById = (
  state: RecipesState,
  { recipeId, groupId, ingredientId, updatedIngredient }: UpdateShoppableIngredientByIdArgs,
) => {
  return {
    ...state,
    [recipeId]: {
      ...state[recipeId],
      shoppableProducts: state[recipeId]?.shoppableProducts?.map((group, i) => {
        if (i === groupId) {
          return {
            ...group,
            ingredients: {
              storeCupboard: group.ingredients.storeCupboard.map(ingredient => {
                if (ingredient.ingredientId === ingredientId) {
                  return updatedIngredient;
                }
                return ingredient;
              }),
              nonStoreCupboard: group.ingredients.nonStoreCupboard.map(ingredient => {
                if (ingredient.ingredientId === ingredientId) {
                  return updatedIngredient;
                }
                return ingredient;
              }),
            },
          };
        }
        return group;
      }),
    },
  };
};

type UpdateShoppableIngredientPriceByIdArgs = {
  recipeId: Recipe['id'];
  groupId: number | null;
  ingredientId: string;
  payload: {
    totals: {
      totalPrice: {
        amount: number;
        currencyCode: string;
      };
    };
  }[];
  updatedIngredient: object;
};

const updateShoppableIngredientPriceById = (
  state: RecipesState,
  {
    recipeId,
    groupId,
    ingredientId,
    payload,
    updatedIngredient,
  }: UpdateShoppableIngredientPriceByIdArgs,
) => {
  const updatedIngredientPrice = {
    ...updatedIngredient,
    totalPrice: formatAsPositivePoundsOrPence(payload?.[0]?.totals?.totalPrice?.amount ?? null),
  };

  return updateShoppableIngredientById(state, {
    recipeId,
    groupId,
    ingredientId,
    updatedIngredient: updatedIngredientPrice,
  });
};

type SwapShoppableIngredientByIdArgs = {
  recipeId: Recipe['id'];
  groupId: number | null;
  ingredientId: string;
  payload?: {
    totals?: {
      totalPrice: {
        amount: number;
      };
    };
  }[];
  swappedIngredient: Ingredient;
};

const swapShoppableIngredientById = (
  state: RecipesState,
  { recipeId, groupId, ingredientId, payload, swappedIngredient }: SwapShoppableIngredientByIdArgs,
) => {
  const recipe = state[recipeId];

  // Filter and update shoppableProducts with the swapped ingredient if it exists
  const updatedShoppableProducts = recipe.shoppableProducts!.map(group => {
    const { storeCupboard, nonStoreCupboard } = group.ingredients;

    const filterIngredients = (ingredients: Ingredient[]) =>
      ingredients.filter(
        (ingredient: Ingredient) => ingredient.lineNumber !== swappedIngredient.lineNumber,
      );

    return {
      ...group,
      ingredients: {
        storeCupboard: filterIngredients(storeCupboard),
        nonStoreCupboard: filterIngredients(nonStoreCupboard),
      },
    };
  });

  // Update the state with the new shoppableProducts
  const updatedState = {
    ...state,
    [recipeId]: {
      ...recipe,
      shoppableProducts: updatedShoppableProducts,
    },
  };

  // Swap the ingredient with the new ingredient and format the price
  const totalPrice = payload?.[0]?.totals?.totalPrice?.amount ?? null;
  return updateShoppableIngredientById(updatedState, {
    recipeId,
    groupId,
    ingredientId,
    updatedIngredient: {
      ...swappedIngredient,
      totalPrice: formatAsPositivePoundsOrPence(totalPrice),
      swapped: true,
    },
  });
};

const productsByRecipeIdSuccess = (state: RecipesState, { result, id }: PimsAction) => {
  const recipe: RecipeStateItem = state[id] || defaultRecipe;
  const products = result.ingredients!.filter(
    (i: Pick<Ingredient, 'products'>) => i.products.length,
  );

  const lineNumbersSet = new Set();

  const shoppableGroups = recipe?.ingredientGroups?.map(group => ({
    ...group,
    ingredients: group.ingredients.reduce(
      (storeCupboardGroups, ingredient) => {
        const pimsIngredient = products.find(p => p.id === ingredient.id);
        const lineNumber = pimsIngredient
          ? pimsIngredient.products[0].lineNumber
          : ingredient.lineNumber;

        const duplicateItem = lineNumbersSet.has(lineNumber);
        lineNumbersSet.add(lineNumber);

        const isStoreCupboard = pimsIngredient?.type === STORE_CUPBOARD;
        const key = isStoreCupboard ? 'storeCupboard' : 'nonStoreCupboard';

        return {
          ...storeCupboardGroups,
          [key]: [
            ...storeCupboardGroups[key],
            {
              ...pimsIngredient,
              lineNumber,
              uom: '',
              amountSelected: duplicateItem || pimsIngredient?.type === STORE_CUPBOARD ? 0 : 1,
              type: pimsIngredient?.type === STORE_CUPBOARD ? STORE_CUPBOARD : CORE,
              ingredientId: uuid(),
              totalPrice: '',
              duplicateItem,
            },
          ],
        };
      },
      { storeCupboard: [], nonStoreCupboard: [] },
    ),
  }));

  return {
    ...state,
    [id]: {
      ...recipe,
      pimsError: !products.length,
      products,
      shoppableProducts: shoppableGroups,
    },
  };
};

const productsByRecipeIdFailure = (state: RecipesState) => {
  const recipe = state[state.id] || defaultRecipe;

  return {
    ...state,
    [state.id]: {
      ...recipe,
      pimsError: true,
    },
  };
};

export type RatingResult = Rating & {
  id: Recipe['id'];
  loaded: boolean;
};

export type RecipeRatingRequestArgs = {
  recipeId: Recipe['id'];
};

type RecipeRatingSuccessArgs = {
  result: RatingResult;
};

const recipeRatingRequest = (state: RecipesState, { recipeId }: RecipeRatingRequestArgs) => {
  return {
    ...state,
    [recipeId]: {
      ...state[recipeId],
      rating: {
        loading: true,
      },
    },
  };
};

const recipeRatingSuccess = (state: RecipesState, { result }: RecipeRatingSuccessArgs) => {
  // note that it is important only to update the recipe for the given id (eg if rating API completes first, state.id will be different)

  const { header, status, id, ...rating } = result; // eslint-disable-line @typescript-eslint/no-unused-vars
  rating.average = Math.round(rating.average * 2) / 2;
  rating.loaded = true;

  return {
    ...state,
    id,
    [id]: {
      ...(state[id] || defaultRecipe),
      rating,
    },
  };
};

const recipeRatingFailure = (state: RecipesState) => {
  const recipe = state[state.id] || defaultRecipe;

  return {
    ...state,
    [state.id]: {
      ...recipe,
      rating: {
        ...recipe.rating,
        error: true,
      },
    },
  };
};

const navToRecipe = (state: RecipesState, { recipe }: NavAction) => {
  const recipeState = state[recipe.id] || defaultRecipe;

  return {
    ...state,
    id: recipe.id,
    title: recipe.title,
    images: recipe.images,
    [recipe.id]: {
      ...recipeState,
      title: recipe.title,
    },
  };
};

const actionTypeReducerMap = [
  [RECIPE_BY_ID.request, recipeByIdRequest],
  [RECIPE_BY_ID.success, recipeByIdSuccess],
  [PRODUCTS_BY_RECIPE_ID.success, productsByRecipeIdSuccess],
  [PRODUCTS_BY_RECIPE_ID.failure, productsByRecipeIdFailure],
  [RECIPE_RATING.request, recipeRatingRequest],
  [RECIPE_RATING.success, recipeRatingSuccess],
  [RECIPE_RATING.failure, recipeRatingFailure],
  [NAV_TO_RECIPE, navToRecipe],
  [SHOPPABLE_PRICING_REQUEST_SUCCESS, updateShoppableIngredientPriceById],
  [UPDATE_SHOPPABLE_INGREDIENT_BY_ID, updateShoppableIngredientById],
  [SWAP_SHOPPABLE_PRICING_REQUEST_SUCCESS, swapShoppableIngredientById],
];

type RecipesReducer = (
  state: Partial<RecipesState> | RecipesErrorState | null,
  args:
    | RecipeAction
    | PimsAction
    | UpdateShoppableIngredientByIdArgs
    | UpdateShoppableIngredientPriceByIdArgs
    | SwapShoppableIngredientByIdArgs
    | RecipeRatingRequestArgs
    | RecipeRatingSuccessArgs
    | NavAction
    | { type: string; action: { type?: string } },
) => RecipesState;

export default createReducer(
  initialState,
  actionTypeReducerMap as any, // eslint-disable-line @typescript-eslint/no-explicit-any
) as unknown as RecipesReducer;

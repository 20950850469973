export const SORT_URL_CATEGORY_RANKING = 'categoryRanking' as const;
export const SORT_URL_PURCHASE_FREQUENCY = 'weightedFavourites' as const;

// Products API
export const SORT_PRODUCTS_API_CATEGORY_RANKING = 'categoryRanking' as const;
export const SORT_PRODUCTS_API_WEIGHTED_FAVOURITES = 'weightedFavourites' as const;

// Favourites Experience API
export const SORT_FAVS_EXP_API_CATEGORY = 'CATEGORY' as const;
export const SORT_FAVS_EXP_API_PURCHASE_FREQUENCY = 'PURCHASE_FREQUENCY' as const;

export const FAVOURITES_SORT_URL_TO_API_MAP = {
  [SORT_URL_CATEGORY_RANKING]: SORT_FAVS_EXP_API_CATEGORY,
  [SORT_URL_PURCHASE_FREQUENCY]: SORT_FAVS_EXP_API_PURCHASE_FREQUENCY,
};

export const FAVOURITES_SORT_API_TO_URL_MAP = {
  [SORT_FAVS_EXP_API_CATEGORY]: SORT_URL_CATEGORY_RANKING,
  [SORT_FAVS_EXP_API_PURCHASE_FREQUENCY]: SORT_URL_PURCHASE_FREQUENCY,
};

import { createSelector } from 'reselect';
import queryString from 'query-string';
import { SORT_URL_CATEGORY_RANKING } from 'redux/modules/favourites/constants';
import { getFavouritesOfferFilterActiveStateLegacy } from 'redux/modules/favourites/selectors/get-favourites-offers';
import { getFeatureFlags } from 'utils/feature-flags';
import { getFavourites } from './get-favourites';

export const getFavouritesLoading = createSelector(getFavourites, favourites => favourites.loading);

export const isFavouritesLoadingMore = createSelector(
  getFavourites,
  favourites => favourites.loadingMore,
);

export const getFavouritesSortingMethod = createSelector(
  [getFavourites],
  favourites => favourites.sortBy || SORT_URL_CATEGORY_RANKING,
);

export const getFavouritesCategoryIdFilter = createSelector(
  getFavourites,
  favourites => favourites.categoryIdFilter,
);

export const isFavouritesFiltered = state =>
  !!(getFavouritesCategoryIdFilter(state) || !!getFavouritesOfferFilterActiveStateLegacy(state));

const ALL_CATEGORIES_LABEL = 'All Categories';
const DEFAULT_CATEGORY_LABEL = 'Filter by';

const getFavouritesCategoriesLegacy = state => state.favourites.rootCategories;
const getFavouritesCategoriesExperience = state => state.favouritesExperience.rootCategories;

export const getFavouritesCategoryOptionsLegacy = createSelector(
  [getFavouritesCategoriesLegacy],
  links => [
    {
      deselector: true,
      text: ALL_CATEGORIES_LABEL,
    },
    ...links.map(link => ({
      value: queryString.parse(link.href.split('?')[1]).categoryId, // really api, really??
      text: link.title,
      count: link.metaData.expectedResults,
    })),
  ],
);

export const getFavouritesCategoryOptionsExperience = createSelector(
  [getFavouritesCategoriesExperience],
  links => [
    {
      deselector: true,
      text: ALL_CATEGORIES_LABEL,
    },
    ...links.map(link => ({
      value: link.categoryId,
      text: link.name,
      count: link.expectedResults.toString(),
    })),
  ],
);

export const getFavouritesCategoryOptions = state => {
  const { build_favouritesExperience: favouritesExperience } = getFeatureFlags();

  return favouritesExperience
    ? getFavouritesCategoryOptionsExperience(state)
    : getFavouritesCategoryOptionsLegacy(state);
};

export const getFavouritesCategoryLabel = createSelector(
  [getFavourites, getFavouritesCategoryOptions],
  (favourites, options) => {
    const selected = options.find(option => option.value === favourites.categoryIdFilter);
    return selected
      ? `${selected.text}${selected.count ? ` (${selected.count})` : ''}`
      : DEFAULT_CATEGORY_LABEL;
  },
);

import history from 'utils/history';
import urls from 'constants/urls';
import locator from 'utils/locator';
import { getFeatureFlags } from 'utils/feature-flags';
import { stashObject, unstashObject } from 'utils/stash';

export const onLoginPage = () => locator.pathname === urls.signIn;

export const loginPagePath = () => {
  return `${urls.login}?redirect=${encodeURIComponent(locator.pathname + locator.search)}`;
};

export const signInPagePath = () => {
  return getFeatureFlags().identity_enableOAuth2Web ? urls.loginOAuth2 : urls.signIn;
};

export const registrationPagePath = () => {
  return getFeatureFlags().identity_enableOAuth2Web ? urls.registrationOAuth2 : urls.registration;
};

export const getLoginRedirect = () => {
  return unstashObject('loginRedirect');
};

export const storeLoginRedirect = redirect => {
  stashObject('loginRedirect', redirect);
};

// use only in event handlers / effects
// in render phase use RedirectToLoginPage
export const redirectToLogin = () => {
  history.push(loginPagePath());
};
